@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.bg-amco {
  background-color: #FBF9F4;
}

.amco-blue {
  background: #004775;
}

.bg-amco-gold-simple {
  background: #B78B1E;
}

.bg-amco-gold {
  background: rgb(160,124,33);
  background: linear-gradient(90deg, rgba(160,124,33,1) 0%, rgba(183,139,29,1) 35%, rgba(211,155,23,1) 100%);
}

.logo-amco {
  width: 250px;
  height: auto;
  margin: 0 auto 0 calc(50% - 140px);
}

.logo-amco-nav {
  width: 250px;
  height: auto;
}

.logo-bportugal {
  width: 48px;
  height: auto;
  align-items: center;
}

.soft-bg {
  background-color:#FBF9F4;
}

.bPortugal-container {
  display: grid;
  background-color:#FBF9F4;
}

.whatsapp-color {
  color: #4ac959;
}

.container-noNav {
  min-height: calc(100vh - 200px);
}

.navBar {
  height: 200px;
}
.roundContainer {
  border-radius: 1.5rem 1.5rem 0 0;
}

.center-screen {
  margin: 0 auto;
}

.otp-form {
  display: block;
}

.spinner {
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top: 4px solid #fff;
  width: 20px;
  height: 20px;
  -webkit-animation: spin 1s linear infinite; /* Safari */
  animation: spin 1s linear infinite;
  margin: 0 auto;
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.manager-container {
  margin-top: 200px;
}

.manager-container img {
  height: 170px;
  margin-top: -70px;
  margin-left: 30px;
}

.content-manager {
  padding-left: 150px;
}



/* MOBILE STYLING */
@media screen and (max-width: 480px) {
  .navBar {
    height: 140px;
  }

  .container-noNav {
    min-height: 100vh;
    height: 100%;
  }

  .mobile-bp {
    margin-top: 30px;
  }

  .otp-form input{
    border: 1px solid #25232C;
    margin: 0 4px;
    border-radius: 8px;
    width: 40px !important;
    height: 40px !important;
    font-size: 28px;
    color: #000;
    font-weight: 700;
  }

  .mobile-creditType {
    margin-bottom: 15px;
  }

  .mobile-spacing {
    margin-bottom: 10px;
  }

  .mobile-spacing-2 {
    margin-bottom: 20px;
  }

  .manager-container {
    margin-top: 80px;
  }

  .manager-container img {
    height: 140px;
    margin-top: -40px;
    margin-left: 10px;
  }

  .content-manager {
    padding-left: 115px;
  }

  .text-12 {
    font-size: 12px;
  }

  .text-10 {
    font-size: 10px;
  }
}